import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import AdminPage from "./Admin";
import {Form} from "./paymentForm/Form";
import axios from "axios";
import {SERVER} from "./utils";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/order/*" element={<Form/>} />
              <Route path="/admin/*" element={<AdminPage />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
